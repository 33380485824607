.chat {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

.conversationsContainer {
  display: flex;
  flex-direction: column;
  /* height: 100%;
  overflow-y: auto; */
}
  
.message {
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
  line-height: 1.5;
  font-size: 18px;
}

.sent {
  align-self: flex-end;
  background-color: #0f7dc2;
  color: white;
}

.received {
  align-self: flex-start;
  background-color: #e5e5ea;
  color: black;
}

.conversationsContainer {
  padding: 0;
}

.conversationLink {
  text-decoration: none;
}

.conversation {
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  background-color: #e5e5ea;
  border: 1px solid #ccc;
  text-decoration: none;
  color: black;
}

.newMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e5e5ea;
  border: 1px solid #ccc;
}

.newMessageText {
  width: 100vw;
  height: 44px;
}

.newMessageButton {
  height: 50px;
}

.newConversationContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  
}

.newConversation {
  width: 80%;
}