.nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: silver
}

.button {
  width: 20%;
  height: 40px;
  background-color: gray;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 40px;

  /* &:hover {
    background-color: #e57373;
  } */
}